<!--Vista que muestra la lista de eventos del organizador-->
<template>
  <v-container fluid class="event-container">
    <v-row :class="$vuetify.breakpoint.smAndUp ? 'mx-5' : 'mx-1'">
      <v-col cols="12">
        <EventCardList
          :items="invitationalEvents.events"
          @fetchEventsAfterDelete="fetchEventsAfterDelete"
        ></EventCardList>
      </v-col>
      <v-col
        v-if="
          invitationalEvents.totalDocs == 0 && !invitationalEvents.hasPrevPage
        "
        cols="12"
      >
        <v-row justify="center">
          <v-col cols="12">
            <p class="headline text-center font-weight-bold">
              No se encontraron resultados
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-img
            src="@/assets/images/general/no_data_events.svg"
            max-width="300"
          ></v-img>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="loadingEvents" class="mb-15">
      <v-col cols="12">
        <p class="title font-weight-bold mb-0 text-center">Cargando eventos</p>
      </v-col>
      <v-col cols="12" offset-sm="3" sm="6">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <!--Si ya no se están cargando los eventos habilitar el páginador mediante el scroll-->
    <v-row v-if="!loaderVisible">
      <v-col cols="12">
        <v-card v-if="!loadingEvents" v-intersect="fetchEvents"></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import EventCard from "@/components/shared/EventCard";
import EventCardList from "@/components/dashboard/event/EventCardList";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {
    eventsSearchData: { type: Object, required: true },
    startSearch: { type: Boolean, required: true },
  },
  components: {
    EventCard,
    EventCardList,
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    eventsSearchDataComputed: {
      get() {
        return this.eventsSearchData;
      },
      set(value) {
        this.$emit("setEventsSearchData", value);
      },
    },
  },
  data() {
    return {
      loadingEvents: false,
      boolUserPermission: false,
      invitationalEvents: {
        events: [],
        totalDocs: null,
        limit: 8,
        totalPages: null,
        page: 1,
        pagingCounter: null,
        hasPrevPage: false,
        hasNextPage: true,
        prevPage: null,
        nextPage: null,
      },
    };
  },
  methods: {
    ...mapActions("event", ["fetchInvitationalEventsPaginated"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),

    shareItem() {
      return `${window.location.origin}/micrositio-eventos/${this.user._id}`;
    },

    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url de los eventos al portapapeles!",
        color: "primary",
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url de los eventos!",
        color: "error",
      });
    },

    formatPermission(permission) {
      return `${permission.action}-${permission.subject}`;
    },

    isValid(permission) {
      return permission.action === "create" && permission.subject === "Event";
    },

    async fetchEvents() {
      if (this.invitationalEvents.hasNextPage == true) {
        this.loadingEvents = true;
        await setTimeout(async () => {
          this.eventsSearchDataComputed.page++;
          let response = await this.fetchInvitationalEventsPaginated(
            this.eventsSearchDataComputed
          );
          console.log(response);
          this.invitationalEvents = response.data;
          this.loadingEvents = false;
        }, 500);
      } else {
        console.log("Ya no hay más eventos");
      }
    },
    async searchEvents() {
      this.loadingEvents = true;
      this.invitationalEvents = {
        events: [],
        totalDocs: null,
        limit: 8,
        totalPages: null,
        page: 1,
        pagingCounter: null,
        hasPrevPage: false,
        hasNextPage: true,
        prevPage: null,
        nextPage: null,
      };
      this.eventsSearchDataComputed.page = 0;
      console.log(this.eventsSearchDataComputed);
      await this.fetchInvitationalEventsPaginated(
        this.eventsSearchDataComputed
      );
      this.loadingEvents = false;
      this.$emit("stopSearch");
    },
    async fetchEventsAfterDelete() {
      await this.searchEvents();
      console.log("Entré a buscar los eventos");
    },
  },
  async created() {
    await this.fetchEvents();
  },
  watch: {
    startSearch: {
      handler: function (val) {
        if (val) {
          this.searchEvents();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
/* html,
body,
.theme--light.v-application,
.v-application {
    background-color: #f9fafb !important;
} */
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}
.event-container {
  min-height: 93vh;
}
</style>