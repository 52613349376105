<template>
  <v-row justify="center" class="my-0 py-4">
    <Chat></Chat>
    <v-col cols="11" class="white rounded-xl">
      <v-row
        :class="$vuetify.breakpoint.smAndUp ? 'mx-5' : 'mx-1'"
        class="my-2"
      >
        <v-col cols="12" md="6" v-if="tab === 0">
          <v-sheet color="accent rounded-xl pl-3">
            <v-text-field
              v-model="eventsSearchData.name"
              solo
              dense
              placeholder="Buscar evento"
              hide-details="auto"
              class="rounded-search"
              clearable
              v-on:keyup.enter="searchEvents()"
              @click:clear="(eventsSearchData.name = ''), searchEvents()"
            >
              <template v-slot:prepend>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on">
                      <v-icon>fa fa-search</v-icon>
                    </v-btn>
                  </template>
                  Clic para buscar evento
                </v-tooltip>
              </template>
            </v-text-field>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6" v-else>
          <v-sheet color="accent rounded-xl pl-3">
            <v-text-field
              v-model="invitationalEventsSearchData.name"
              solo
              dense
              placeholder="Buscar evento"
              hide-details="auto"
              class="rounded-search"
              clearable
              v-on:keyup.enter="searchEvents()"
              @click:clear="
                (invitationalEventsSearchData.name = ''), searchEvents()
              "
            >
              <template v-slot:prepend>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on">
                      <v-icon>fa fa-search</v-icon>
                    </v-btn>
                  </template>
                  Clic para buscar evento
                </v-tooltip>
              </template>
            </v-text-field>
          </v-sheet>
        </v-col>

        <v-col
          cols="6"
          md="3"
          :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-end' : ''"
        >
          <v-btn
            text
            class="font-weight-bold black--text"
            v-clipboard:copy="shareItem()"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            @click="resetSearch"
          >
            <v-icon class="mr-1"> mdi-link-variant </v-icon>
            Todos los eventos
          </v-btn>
        </v-col>
        <v-col cols="6" md="3" class="d-flex justify-end">
          <v-btn
            to="/nuevo-evento"
            color="accent"
            :small="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
            :fab="$vuetify.breakpoint.smAndDown"
            :disabled="!user.permissions.Event.create"
          >
            <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
            <span v-else class="font-weight-bold black--text"
              >+ Nuevo evento</span
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-tabs
        :color="color.primaryVariant"
        v-model="tab"
        @change="resetEventsSearchData()"
      >
        <v-tab>
          <span class="wc-caption-1">Eventos</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">Eventos por invitación</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Events
            :eventsSearchData="eventsSearchData"
            :startSearch="startSearch"
            @setEventsSearchData="setEventsSearchData()"
            @stopSearch="stopSearch()"
          ></Events>
        </v-tab-item>

        <v-tab-item>
          <InvitationalEvents
            :eventsSearchData="invitationalEventsSearchData"
            :startSearch="startSearch"
            @setEventsSearchData="setInvitationalEventsSearchData()"
            @stopSearch="stopSearch()"
          ></InvitationalEvents>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Chat from "@/components/shared/Chat.vue";

import { colors } from "@/constants/colors";
import { mapState } from "vuex";
import Events from "../Events.vue";
import InvitationalEvents from "./InvitationalEvents.vue";

export default {
  name: "MyEvents",
  components: {
    Events,
    InvitationalEvents,
    Chat,
  },
  data() {
    return {
      color: colors,
      tab: null,
      eventsSearchData: {
        name: "",
        page: 0,
        perPage: 12,
      },
      invitationalEventsSearchData: {
        name: "",
        page: 0,
        perPage: 12,
      },
      startSearch: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    searchEvents() {
      this.startSearch = true;
    },

    resetSearch() {
      if (this.tab === 0) {
        // Si la pestaña actual es la de "Eventos"
        this.eventsSearchData = {
          name: "",
          page: 0,
          perPage: 12,
        };
      } else {
        // Si la pestaña actual es la de "Eventos por invitación"
        this.invitationalEventsSearchData = {
          name: "",
          page: 0,
          perPage: 12,
        };
      }
      // Iniciar la búsqueda nuevamente
      this.searchEvents();
    },
    stopSearch() {
      this.startSearch = false;
    },
    resetEventsSearchData() {
      this.eventsSearchData = {
        name: "",
        page: 0,
        perPage: 12,
      };
    },
    shareItem() {
      return `${window.location.origin}/micrositio-eventos/${this.user._id}`;
    },

    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url de los eventos al portapapeles!",
        color: "primary",
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url de los eventos!",
        color: "error",
      });
    },

    setEventsSearchData(value) {
      this.eventsSearchData = value;
    },

    setInvitationalEventsSearchData(value) {
      this.invitationalEventsSearchData = value;
    },
  },
};
</script>

<style>
</style>