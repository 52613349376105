<!--Tarjeta de evento que se muestra en el dashboard de organización-->
<template>
  <v-hover v-slot="{ hover }">
    <!--:class="!event.published?'grayscale-disabled-element':''"-->
    <v-card
      class="mx-auto event-card"
      rounded="xl"
      max-width="344"
      :elevation="hover ? '4' : '0'"
      :class="
        !isValidDate(event.dateConfiguration)
          ? ''
          : 'grayscale-disabled-element'
      "
    >
      <v-img
        v-if="event.banner && event.banner != ''"
        :src="event.banner"
        :gradient="
          hover
            ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
            : ''
        "
        :height="180"
        @click="editEvent()"
      >
        <!--<v-row v-if="hover" justify="end" class="ma-3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="light-blue darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              v-clipboard:copy="shareItem(event._id)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>
              fas fa-link
              </v-icon>
            </v-btn>
          </template>
          <span>Compartir URL de evento</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              @click="openDialogToDeleteEvent(event._id)"
            >
              <v-icon>
              far fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar evento</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="yellow darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              @click="editEvent()"
            >
              <v-icon>
              fas fa-pencil-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Configurar evento</span>
        </v-tooltip>
      </v-row>-->
      </v-img>
      <v-img
      v-else-if="(!event.banner || event.banner === '') && event.flyer && event.flyer != ''"
      :src="firstFlyerImage"
      :gradient="
        hover
          ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
          : ''
      "
      :height="180"
      @click="editEvent()"
    >
  </v-img>

      <v-img
        v-else
        src="@/assets/images/shared/withoutbanner.png"
        :gradient="
          hover
            ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
            : ''
        "
        :height="180"
        @click="editEvent()"
      >
        <!--<v-row v-if="hover" justify="end" class="ma-3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="light-blue darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              v-clipboard:copy="shareItem(event._id)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>
              fas fa-link
              </v-icon>
            </v-btn>
          </template>
          <span>Compartir URL de evento</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              @click="openDialogToDeleteEvent(event._id)"
            >
              <v-icon>
              far fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar evento</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="yellow darken-4"
              icon
              v-bind="attrs"
              v-on="on"
              @click="editEvent()"
            >
              <v-icon>
              fas fa-pencil-alt
              </v-icon>
            </v-btn>
          </template>
          <span>Configurar evento</span>
        </v-tooltip>
      </v-row>-->
      </v-img>


      <v-card-title
        class="title font-weight-black d-inline-block text-truncate pt-1"
        style="max-width: 100%"
        @click="editEvent()"
      >
        {{ event.name }}
      </v-card-title>

      <v-card-subtitle>
        <v-row dense>
          <v-col cols="12" @click="editEvent()">
            <p class="mb-0 subtitle-1 font-weight-bold">
              {{ formatDate(event.dateConfiguration.startDate) }}
            </p>
          </v-col>
          <v-col cols="6" @click="editEvent()">
            <p
              v-if="event.published"
              class="mb-0 subtitle-1 secondary--text font-weight-bold"
            >
              Publicado
            </p>
            <p v-else class="mb-0 subtitle-1 accent--text font-weight-bold">
              No publicado
            </p>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <div class="rounded-xl px-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-4"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!user.permissions.Event.delete"
                    @click="openDialogToDeleteEvent(event._id)"
                  >
                    <v-icon> far fa-trash-alt </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar evento</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="light-blue darken-4"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    v-clipboard:copy="shareItem(event._id)"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    <v-icon> fas fa-link </v-icon>
                  </v-btn>
                </template>
                <span>Compartir URL de evento</span>
              </v-tooltip>
              <!--<v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="yellow darken-4"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editEvent()"
                >
                  <v-icon>
                  fas fa-pencil-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Configurar evento</span>
            </v-tooltip>-->
            </div>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-hover>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    event: { type: Object, required: true },
  },
  data() {
    return {
      eventSelectedId: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    firstFlyerImage() {
    if (this.event.flyer && this.event.flyer.includes(',')) {
      return this.event.flyer.split(',')[0];
    }
    return this.event.flyer;
  }
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    editEvent() {
      this.$router.push(`/mi-evento/micrositio/${this.event._id}`);
    },
    openDialogToDeleteEvent(eventSelectedId) {
      this.$emit("openDialogToDeleteEvent", eventSelectedId);
    },
    /**
     * Función que devuelve un link para compartir el evento seleccionado.
     * @param { string } id Identificador único de un evento.
     */
    shareItem(id) {
      return `${window.location.origin}/micrositio/${id}`;
    },
    formatDate(date) {
      let eventDate = new Date(date.replace(".000Z", ""));
      return eventDate.toLocaleDateString("es-MX").replaceAll("/", "-");
    },
    isValidDate({ endDate, endTime }) {
      let currentDate = new Date();
      let eventDate = new Date(
        endDate.replace(".000Z", "").replace("00:00:00", `${endTime}:00`)
      );
      if (currentDate <= eventDate) {
        return false;
      } else {
        return true;
      }
    },
    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url del evento al portapapeles!",
        color: "primary",
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url del evento!",
        color: "error",
      });
    },
  },
};
</script>
<style scoped>
.event-card {
  transition: all 0.8s;
}

.event-card:hover {
  transform: scale(1.05);
}

.grayscale-disabled-element {
  filter: grayscale(1);
}
</style>